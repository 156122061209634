<template lang="html">
<v-container>
  <v-card icon="mdi-account" >
		<v-card-text>
		<h1 class="display-2 my-2">Form Ekspektasi Perilaku Rekan Anda</h1>
		<hr>
		<h2 style="font-family:Segoe UI" class="my-2">Core Values ASN BerAKHLAK</h2>
		<label style="font-family:Segoe UI"><b>Ber</b>orientasi Pelayanan, <b>A</b>kuntabel, <b>K</b>ompeten, <b>H</b>armonis, <b>L</b>oyal, <b>A</b>daptif, <b>K</b>olaboratif.</label>
		</v-card-text>
		<!-- <v-spacer></v-spacer> -->
    <v-form ref="formTambah">
      <v-row class="mt-1">
        <v-col align="center" cols="4">
          <base-material-card
            class="v-card-profile"
            :avatar="dataPegawaiBawahan.foto"
            style="width:270px;margin-top:40px"
          >
            <v-card-text class="text-center" style="height:150px">
              <h4 class="display-1 mb-1 orange--text text--darken-2" style="height:55px;">
                {{dataPegawaiBawahan.peg_nama}}
              </h4>
              <h4 class="font-weight-light grey--text mb-2">
                NIP. {{dataPegawaiBawahan.peg_nip}}
              </h4>
              <h4 class="font-weight-light green--text" style="font-size:12px; height:30px">
                {{dataPegawaiBawahan.jabatan_nama}}
              </h4>
              <div class="mt-5">
              </div>
            </v-card-text>

          </base-material-card>
        </v-col>
        <v-col cols="7">
          <v-row >
            <v-col cols=7 align="left">
              <h3 style="font-family:Segoe UI" class="my-2"><b>Ber</b>orientasi Pelayanan</h3>
              <label style="font-family:Segoe UI">- Memahami dan memenuhi kebutuhan masyarakat.</label>
              <br>
              <label style="font-family:Segoe UI">- Ramah, cekatan, solutif, dan dapat diandalkan.</label>
              <br>
              <label style="font-family:Segoe UI">- Melakukan perbaikan tiada henti.</label>
            </v-col>
            <v-col cols=5>
              <v-textarea :rules="[rules.required]" validate-on-blur="true" v-model="dataEkspektasiBawahan.e_berorientasi_pelayanan" label="Ekspektasi Anda Terkait Berorientasi Pelayanan" outlined :auto-grow="autoGrow"></v-textarea>
            </v-col>
          </v-row>
          <v-row >
            <v-col cols=7 align="left">
              <h3 style="font-family:Segoe UI" class="my-2"><b>A</b>kuntabel</h3>
              <label style="font-family:Segoe UI">- Melaksanakan tugas dengan jujur, bertanggung jawab, cermat, disiplin, dan berintegritas tinggi.</label>
              <br>
              <label style="font-family:Segoe UI">- Menggunakan kekayaan dan barang milik negara secara bertanggung jawab, efektif, dan efisien.</label>
              <br>
              <label style="font-family:Segoe UI">- Tidak menyalahgunakan kewenangan jabatan.</label>
            </v-col>
            <v-col cols=5>
              <v-textarea :rules="[rules.required]" validate-on-blur="true" v-model="dataEkspektasiBawahan.e_akuntabel" label="Ekspektasi Anda Terkait Akuntabel" outlined :auto-grow="autoGrow"></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols=7 align="left">
              <h3 style="font-family:Segoe UI" class="my-2"><b>K</b>ompeten</h3>
              <label style="font-family:Segoe UI">- Meningkatkan kompetensi diri untuk menjawab tantangan yang selalu berubah.</label>
              <br>
              <label style="font-family:Segoe UI">- Membantu orang lain belajar.</label>
              <br>
              <label style="font-family:Segoe UI">- Melaksanakan tugas dengan kualitas terbaik.</label>
            </v-col>
            <v-col cols=5>
              <v-textarea :rules="[rules.required]" validate-on-blur="true" v-model="dataEkspektasiBawahan.e_kompeten" label="Ekspektasi Anda Terkait Kompeten" outlined :auto-grow="autoGrow"></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols=7 align="left">
              <h3 style="font-family:Segoe UI" class="my-2"><b>H</b>armonis</h3>
              <label style="font-family:Segoe UI">- Menghargai setiap orang apapun latar belakangnya.</label>
              <br>
              <label style="font-family:Segoe UI">- Suka menolong orang lain.</label>
              <br>
              <label style="font-family:Segoe UI">- Membangun lingkungan kerja yang kondusif.</label>
            </v-col>
            <v-col cols=5>
              <v-textarea :rules="[rules.required]" validate-on-blur="true" v-model="dataEkspektasiBawahan.e_harmonis" label="Ekspektasi Anda Terkait Harmonis" outlined :auto-grow="autoGrow"></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols=7 align="left">
              <h3 style="font-family:Segoe UI" class="my-2"><b>L</b>oyal</h3>
              <label style="font-family:Segoe UI">- Memegang teguh ideologi Pancasila, Undang-Undang Dasar Negara Republik Indonesia 1945, NKRI serta pemerintahan yang sah.</label>
              <br>
              <label style="font-family:Segoe UI">- Menjaga nama baik sesama ASN, Pimpinan, Instansi, dan Negara.</label>
              <br>
              <label style="font-family:Segoe UI">- Menjaga rahasia jabatan dan negara.</label>
            </v-col>
            <v-col cols=5>
              <v-textarea :rules="[rules.required]" validate-on-blur="true" v-model="dataEkspektasiBawahan.e_loyal" label="Ekspektasi Anda Terkait Loyal" outlined :auto-grow="autoGrow"></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols=7 align="left">
              <h3 style="font-family:Segoe UI" class="my-2"><b>A</b>daptif</h3>
              <label style="font-family:Segoe UI">- Cepat menyesuaikan diri menghadapi perubahan.</label>
              <br>
              <label style="font-family:Segoe UI">- Terus berinovasi dan mengembangkan kreativitas.</label>
              <br>
              <label style="font-family:Segoe UI">- Bertindak proaktif.</label>
            </v-col>
            <v-col cols=5>
              <v-textarea :rules="[rules.required]" validate-on-blur="true" v-model="dataEkspektasiBawahan.e_adaptif" label="Ekspektasi Anda Terkait Adaptif" outlined :auto-grow="autoGrow"></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols=7 align="left">
              <h3 style="font-family:Segoe UI" class="my-2"><b>K</b>olaboratif</h3>
              <label style="font-family:Segoe UI">- Memberi kesempatan kepada berbagai pihak untuk berkontribusi.</label>
              <br>
              <label style="font-family:Segoe UI">- Terbuka dalam bekerja sama untuk menghasilkan nilai tambah.</label>
              <br>
              <label style="font-family:Segoe UI">- Menggerakkan pemanfaatan berbagai sumber daya untuk tujuan bersama.</label>
            </v-col>
            <v-col cols=5>
              <v-textarea :rules="[rules.required]" validate-on-blur="true" v-model="dataEkspektasiBawahan.e_kolaboratif" label="Ekspektasi Anda Terkait Kolaboratif" outlined :auto-grow="autoGrow"></v-textarea>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>

		<v-card-text><hr></v-card-text>
		<v-card-actions class="d-flex justify-center">
      <v-btn @click="closeEvent()" color="warning">tutup</v-btn>
			<v-btn @click="simpanEkspektasiPerilakuKerja()" color="primary">Simpan Ini</v-btn>
    </v-card-actions>
  </v-card>
</v-container>
</template>

<script>
// import _ from 'lodash'
import store from '@/store'
import {defaultRules} from '@/utils/lib'
import SKPService from '@/services/SKPService'

  export default {
    props:{
			dataPegawaiBawahan:Object,
      dataEkspektasiBawahan:Object,
		},
    data() {
        return {
          rules:{
            ...defaultRules
          },
					items: [],
          form: [],
          loadingBtn:false,
          autoGrow:true,
        };
    },
    methods: {
      closeEvent(){
        // this.refreshEkspektasiPerilakuKerja(this.dataPegawaiBawahan.peg_nip)
        this.$emit('onClose',false)
			},
      // refreshEkspektasiPerilakuKerja(peg_nip){
      //   SKPService.getEkspektasiPerilakuKerja(peg_nip).then(response=>{
      //     let res =response.data
      //     this.form = res.data
      //   })
      // },
      simpanEkspektasiPerilakuKerja(){
        if (this.$refs.formTambah.validate()){
          this.form = this.dataEkspektasiBawahan
          this.form.nip = this.dataPegawaiBawahan.peg_nip
          this.loadingBtn = true
          this.form.respon = false
          var date = new Date()
          this.form.bulan = date.getMonth() + 1
          this.form.triwulan = null
          this.form.tahun = this.$store.getters["user/data"].tahun
          SKPService.storeEkspektasiPerilakuKerja(this.form).then(response => {
            let res = response.data
            if (res.success){
              store.commit('snackbar/setSnack',{message: res.message, color:'success'})
            } else {
              store.commit('snackbar/setSnack',{message: res.message, color:'error'})
            }
          }).finally(()=>{
            this.loadingBtn=false
            this.closeEvent()
          })
        }
      },
    },
  }
</script>
